<template>
  <Form
    @invalid-submit="onInvalidSubmit"
    @submit="onSubmit"
    :initial-values="sessione"
    :validation-schema="schema"
    autocomplete="off"
    class="card shadow mt-3"
    :class="windowWidth <= 767 ? '' : 'me-2'"
    v-slot="{ values }"
  >
    <!-- #357ffa -->
    <div class="card-header px-3">
      <div class="my-2 d-flex w-100 justify-content-between text-white">
        <div class="fw-bold">{{ sessione.description }}</div>
        <div class="fw-bold">
          {{ moment(sessione.date).format("DD/MM/YYYY HH:mm") }}
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row mb-2">
        <div class="col-6 d-flex p-0">
          <Field
            name="selected"
            class="align-self-start mt-2 me-2"
            type="checkbox"
            :value="true"
            :checked="values.selected"
            @change="
              $event.target.checked ? '' : (selectedAccompagnatori = []);
              $event.target.checked ? '' : (values.selected_guest = false);
              onSubmit(values);
            "
            @click="nonAggiungereASessioniSelezionate = false"
            :disabled="
              !puoModificareInvito ||
              (invito.max_sessions == numberSessioniOspiteSelected &&
                !values.selected) ||
              !canEnterSession(sessione.date)
            "
          />
          <label class="fw-500 fs-7">Partecipo a questa sessione </label>

          <ErrorMessage v-show="view_errors" name="can_invitation_send" />
        </div>
        <div class="col-6 d-flex p-0">
          <!-- REGOLA GIUNTA
            (invito.id_group == 71 &&
                (values.id_session == 5 || values.id_session == 7)) -->
          <!-- (invito.id_group == 70 &&
                (values.id_session == 3 ||
                  values.id_session == 5 ||
                  values.id_session == 7)) -->
          <Field
            name="selected_guest"
            id="selected_guest_id"
            class="align-self-start mt-2 me-2"
            type="checkbox"
            :value="true"
            :checked="values.selected_guest || selectedAccompagnatori.length"
            :disabled="
              !values.selected ||
              (values.selected && !accompagnatoriOptions.length) ||
              !puoModificareInvito ||
              (invito.max_sessions == numberSessioniOspiteSelected &&
                !values.selected) ||
              !canEnterSession(sessione.date) ||
              !puoModificareInvito
            "
            v-model="selectedGuest"
            @click="
              nonAggiungereASessioniSelezionate = true;
              onSubmit(values);
            "
            @change="
              $event.target.checked ? '' : (values.selected_guest = false)
            "
          />
          <label class="fw-500 fs-7">Ho un accompagnatore</label>

          <ErrorMessage v-show="view_errors" name="can_invitation_send" />
        </div>
      </div>
      <div class="row">
        <div style="min-height: 1.5rem">
          <ErrorMessage name="guest" />
        </div>
        <Multiselect
          :key="accompagnatoriOptions.length"
          v-model="selectedAccompagnatori"
          :options="accompagnatoriOptions"
          class="p-0"
          mode="tags"
          groupLabel="label"
          :placeholder="
            accompagnatoriOptions
              ? 'Scegli accompagnatori tra quelli in elenco'
              : 'Nessun accompagnatore disponibile'
          "
          label="nominative"
          valueProp="id_guest"
          :close-on-select="false"
          :searchable="true"
          :create-option="false"
          :disabled="
            !values.selected_guest ||
            (invito.max_sessions == numberSessioniOspiteSelected &&
              !values.selected) ||
            !canEnterSession(sessione.date) ||
            !puoModificareInvito
          "
          :max="invito.max_guest_sessions"
        />
        <Field name="guest" type="hidden" v-model="selectedAccompagnatori" />

        <button
          type="button"
          :id="`submit${sessione.id_session}`"
          class="d-none"
          @click="onSubmit(values)"
        ></button>
        <!-- <ErrorMessage v-show="view_errors" name="surname" /> -->
      </div>
    </div>
  </Form>
</template>
<script>
import { defineComponent, onBeforeUnmount, onMounted, ref, watch } from "vue";
import moment from "moment";
import { Form, Field, ErrorMessage } from "vee-validate";
import Multiselect from "@vueform/multiselect";
import { useWindowWidth } from "@/composables/getWindowWidth";
import { INVITI_STATUS } from "@/enums/costanti";
import * as yup from "yup";
import { alertFailed } from "@/functions/swAlert";
export default defineComponent({
  components: {
    Multiselect,
    Form,
    Field,
    ErrorMessage,
  },
  emits: ["single_session"],
  props: {
    sessione: {
      type: Object,
    },
    accompagnatori: {
      type: Array,
    },
    invito: {
      type: Array,
    },
    numberSessioniOspiteSelected: {
      type: Number,
    },
    isConsigliere: {
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const selectedAccompagnatori = ref([]);
    const selectedGuest = ref(false);

    if (props.sessione && props.sessione.guest && props.sessione.guest.length) {
      props.sessione.guest.forEach((element) => {
        selectedAccompagnatori.value.push(element.id_guest);
      });
      selectedGuest.value = true;
    }

    const nameSurname = ({ name, surname }) => {
      return `${name} ${surname}`;
    };
    const { windowWidth } = useWindowWidth();

    watch(
      selectedAccompagnatori,
      () => {
        console.log("watch selectedAccompagnatori");
        if (
          selectedAccompagnatori.value.length <= props.invito.max_guest_sessions
        ) {
          document.getElementById(`submit${props.sessione.id_session}`).click();
        }
        if (
          selectedAccompagnatori.value.length &&
          props.invito.id_status >= INVITI_STATUS.DA_CONFERMARE
        ) {
          document.getElementById("selected_guest_id").checked = true;
        }
        // console.log(
        //   `submit${props.sessione.id_session}`,
        //   document.getElementById(`submit${props.sessione.id_session}`)
        // );
        // document.getElementById(`submit${props.sessione.id_session}`).click();
      },
      { deep: true }
    );

    const nonAggiungereASessioniSelezionate = ref(false);

    const onSubmit = (values) => {
      console.log("onSubmit singola sessione");
      emit("single_session", {
        values,
        selectedAccompagnatori: selectedAccompagnatori.value,
        nonAggiungereASessioniSelezionate:
          nonAggiungereASessioniSelezionate.value,
      });
      // nonAggiungereASessioniSelezionate.value = false;
    };

    watch(selectedGuest, (val) => {
      if (!val) {
        selectedAccompagnatori.value = [];
      }
    });
    const clickFromPartecipo = ref(false);

    const canEnterSession = (sessionStart) => {
      const now = new Date();
      const sessionStartDate = new Date(sessionStart);
      const twelveHoursAfterSession = new Date(
        sessionStartDate.getTime() + 12 * 60 * 60 * 1000
      );
      // sessionStartDate.getTime() - 24 * 60 * 60 * 1000
      return now < twelveHoursAfterSession;
      // return new Date(date) < new Date();
    };
    const schema = yup.object().shape({
      guest: yup
        .array()
        .test(
          "Numero di invitati",
          "E' stato raggiunto il numero massimo di accompagnatori autorizzati per questa sessione",
          (value, context) => {
            console.log(
              selectedAccompagnatori.value.length ==
                props.invito.max_guest_sessions,
              selectedAccompagnatori.value.length,
              props.invito.max_guest_sessions
            );
            return !(
              selectedAccompagnatori.value.length ==
              props.invito.max_guest_sessions
            );
          }
        ),
    });

    // onMounted(() => {
    //   document.addEventListener("click", gestioneClick);
    // });
    // onBeforeUnmount(() => {
    //   document.removeEventListener("click", gestioneClick);
    // });
    // const gestioneClick = (event) => {
    //   console.log(event);
    //   if (event.target.classList.contains("multiselect-tags")) {
    //     selectedAccompagnatori.value.length == props.invito.max_guest_sessions
    //       ? alertFailed(
    //           `E' stato raggiunto il numero massimo di accompagnatori per questa sessione`
    //         )
    //       : "";
    //   }
    // };
    return {
      schema,
      alertFailed,
      canEnterSession,
      nonAggiungereASessioniSelezionate,
      clickFromPartecipo,
      selectedGuest,
      onSubmit,
      moment,
      selectedAccompagnatori,
      nameSurname,
      windowWidth,
      INVITI_STATUS,
    };
  },
  computed: {
    accompagnatoriOptions() {
      let arr = [];
      if (this.accompagnatori && this.accompagnatori.results) {
        this.accompagnatori.results.forEach((el) => {
          el.jsonValue = { id_guest: el.id_guest };
          arr.push(el);
        });
      }

      return arr;
    },
    puoModificareInvito() {
      return (
        this.invito.id_status < INVITI_STATUS.DA_CONFERMARE ||
        (this.invito.id_status == INVITI_STATUS.CONFERMATO &&
          this.invito.can_invitation_send)
      );
    },
  },
});
</script>
<style>
.card-header {
  background-color: #357ffa !important;
  min-height: auto !important;
  /* min-width: 350px; */
}
.multiselect-placeholder {
  font-size: small;
}
</style>
